@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.swiper-scrollbar-drag {
  background: red;
}

.swiper .swiper-slide {
  flex-direction: column;
}

.alert-radio-label {
  color: white;
}

.alert-radio-label.sc-ion-alert-md {
  color: white;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  text-overflow: clip !important;
  white-space: normal !important;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: #00a4ef;
}

.alert-checkbox-group.sc-ion-alert-md,
.alert-radio-group.sc-ion-alert-md {
  padding-bottom: 8px;
}

button.action-sheet-button.ion-activatable.ion-focusable.action-sheet-selected.sc-ion-action-sheet-md {
  color: #00a4ef;
}

button.action-sheet-button.ion-activatable.ion-focusable {
  color: #172848;
}

button.action-sheet-button.ion-activatable.ion-focusable.action-sheet-selected.sc-ion-action-sheet-ios {
  color: #00a4ef;
}

button.action-sheet-button.ion-activatable.ion-focusable.action-sheet-.sc-ion-action-sheet-ios {
  color: #172848;
  background: #fdf3db;
}

.alert-radio-label.sc-ion-alert-ios {
  color: #fff;
}

.tab-selected ion-icon {
  color: #172848;
}

.titolo-toolbar {
  /*   margin-left: 10px !important; */
  font-weight: bold !important;
}

ion-toolbar {
  --background: #3780ff;
  color: white;
}

#home .text-input,
#checkout-ombrellone .text-input,
#checkout-abbonamento .text-input {
  text-transform: uppercase;
}

.testoCustom {
  color: #172848 !important;
}

.button-round,
.button-round-md {
  background: #f3403c !important;
  color: #ffffff !important;
}

h2,
h3,
p {
  color: #172848 !important;
}

.dataOmbrelloni {
  color: #ffffff !important;
}

.centraImmagine {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.label,
.select-text,
.datetime-text {
  color: #172848 !important;
}

.button-round-md {
  background: transparent;
  color: #172848;
}

.action-sheet-md .action-sheet-button {
  color: #172848 !important;
}

.txtServizio {
  font-size: 2.8vw;
}

.scroll-content {
  background: #181818 !important;
}

ion-content.has-refresher>.scroll-content {
  background: #181818 !important;
}

.tabbar {
  background: #121212 !important;
  //   background: color($colors, darkthemeseconda) !important;
}

ion-tab-bar {
  --background: #121212 !important;
}

.tabs-md .tab-button,
.tabs-ios .tab-button {
  color: rgba(255, 255, 255, 1);
}

.tabs-md .tab-button-icon,
.tabs-ios .tab-button-icon {
  color: rgba(255, 255, 255, 1);
}

/* ion-input,
ion-icon,
ion-list {
  color: #fff;
}
 */
.item-input ion-input,
.item-input ion-textarea {
  position: static;
}

.card,
.card-title {
  color: #fff !important;
}

/* ion-card {
  background: #181818 !important;
} */

.alert-wrapper {
  background: #181818 !important;
}

main {
  background-color: #181818 !important;
}

.alert-title,
.alert-sub-title,
.alert-message,
.alert-button {
  color: #fff !important;
}

.ios page-home #home .segment-ios .segment-activated,
.md page-home #home .segment-ios .segment-activated {
  border-radius: 0 !important;
}

.ios page-home #home .segment-ios .segment-activated>img,
.md page-home #home .segment-ios .segment-activated>img {
  border: 3px solid white;
  border-radius: 50%;
}

.segment-md .segment-button.activated,
.segment-md .segment-button.segment-activated {
  border-color: transparent;
}

.segment-md .segment-button.activated>img,
.segment-md .segment-button.segment-activated>img {
  border: 3px solid white;
  border-radius: 50%;
}

.segment-ios .segment-button.segment-activated {
  background-color: transparent !important;
}

.action-sheet-title {
  font-size: 1.8rem !important;
  text-transform: uppercase;
  color: #fff !important;
}

.picker-above-highlight {
  background: linear-gradient(to bottom, grey 2%, rgba(24, 24, 24, 0.7) 50%) !important;
}

.picker-below-highlight {
  background: linear-gradient(to top, grey 2%, rgba(24, 24, 24, 0.7) 50%) !important;
}

.card-background-page {
  ion-card {
    position: relative;
    text-align: left;
    //border-radius: 8px!important;
  }

  .card-title {
    position: absolute;
    top: 26%;
    left: 5%;
    font-size: 7vw;
    width: 100%;
    font-weight: bold;
  }

  .card-subtitle {
    font-size: 3.5vw;
    position: absolute;
    top: 55%;
    left: 5%;
    width: 100%;
  }
}

.item-native {
  padding: 0 !important;
}

.button-ios,
.button-md,
.button-solid {
  height: 40px !important;
}

.logo__container__prenotazioni {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: start;
  width: 100%;
  align-items: center;
}

.space__between {
  justify-content: space-between;
}

.justify__center {
  justify-content: center;
}

.justify__start {
  justify-content: flex-start;
}

.justify__end {
  justify-content: flex-end;
}

.align__items__center {
  align-items: center;
}

.align__items__end {
  align-items: end;
}

.font__size__20 {
  font-size: 20px;
}

ion-button.modale__chiudi {
  --border-radius: 0% !important;
  height: 40px !important;
  float: right;
  --color: white;
}

ion-button {
  --border-width: 0;
  height: 40px !important;
}

.sc-ion-modal-ios-h ion-input,
.sc-ion-modal-ios-h ion-icon,
.sc-ion-modal-ios-h ion-list,
.sc-ion-modal-ios-h p {
  font-size: 1.15rem;
}

ion-icon.icona__aggiungi__rimuovi.ios.hydrated {
  font-size: 1.5rem;
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  .sc-ion-modal-ios-h {
    --width: 100% !important;
    --height: 100% !important;
    --border-radius: 0 !important;
  }

  .sc-ion-modal-ios-h ion-input,
  .sc-ion-modal-ios-h ion-icon,
  .sc-ion-modal-ios-h ion-list {
    font-size: 1.5rem;
  }

  .logo__container__prenotazioni ion-icon.ios.hydrated,
  .logo__container__prenotazioni p {
    font-size: 18px;
  }
}

/* UTILIZZATO PER CAMBIARE IL FONT, INSIEME ALLA MODIFICA AL FILE INDEX.HTML */
:root {
  --ion-font-family: "Quicksand" !important;
  font-family: "Quicksand" !important;
}

/* UTILIZZATO PER CAMBIARE IL FONT, INSIEME ALLA MODIFICA AL FILE INDEX.HTML */

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

p,
ion-datetime,
ion-button,
ion-label {
  font-size: 1.15rem;
}

.testo-titolo {
  // background-color: #172848 !important;
  margin: 0;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated {
  font-size: unset;
}

ion-toolbar {
  text-align: center;
}

.remove_inner_bottom {
  --inner-border-width: 0;
}

.password__container {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.icona__mostra__password {
  position: absolute;
  z-index: 99999;
  right: 25px;
  font-size: 1.15rem;
}

/* REGOLE CSS GENERALIZZATE PER STILI E MODIFICARE LAYOUT */

.margin__top__bottom__8 {
  margin: 8px 0;
}

.margin__top__2 {
  margin-top: 2px;
}

.margin__top__4 {
  margin-top: 4px;
}

.text__red {
  color: red !important;
}

.text__white {
  color: white !important;
}

.text__green {
  color: green !important;
}

.text__right {
  text-align: right;
}

.text__bold {
  font-weight: bold;
}

.text__uppercase {
  text-transform: uppercase;
}

.padding__left__right__5 {
  padding: 0 5px;
}

.padding__left__right__10 {
  padding: 0 10px;
}

.padding__left__right__16 {
  padding: 0 16px;
}

.padding__left__right__8 {
  padding: 0 8px;
}

.padding__right__5 {
  padding-right: 5px;
}

.padding__right__16 {
  padding-right: 16px;
}

.padding__left__19 {
  padding-left: 19px;
}

.padding__right__12 {
  padding-right: 12px;
}

.padding__left__8 {
  padding-left: 8px;
}

.padding__left__16 {
  padding-left: 16px;
}

.padding__top__16 {
  padding-top: 16px;
}

.padding__bottom__16 {
  padding-bottom: 16px;
}

.padding__top__8 {
  padding-top: 8px;
}

.padding__top__0 {
  padding-top: 0px;
}

.padding__bottom__40 {
  padding-bottom: 40px !important;
}

.padding__bottom__80 {
  padding-bottom: 80px !important;
}

.padding__bottom__0 {
  padding-bottom: 0px !important;
}

.padding__0 {
  padding: 0;
}

.padding__16 {
  padding: 16px;
}

.padding__14 {
  padding: 14px;
}

.padding__8 {
  padding: 8px;
}

.padding__25 {
  padding: 25px;
}

.height__2_25rem {
  height: 2.25rem !important;
}

.height__40px {
  height: 40px !important;
}

.height__100 {
  height: 100%;
}

.height__90 {
  height: 90%;
}

.height__95 {
  height: 95%;
}

.margin__16 {
  margin: 16px;
}

.margin__bottom__8 {
  margin-bottom: 8px;
}

.margin__bottom__16 {
  margin-bottom: 16px;
}

.margin__top__0 {
  margin-top: 0px;
}

.margin__top__16 {
  margin-top: 16px;
}

.margin__top__25 {
  margin-top: 25px;
}

.margin__bottom__0 {
  margin-bottom: 0px !important;
}

.margin__left__16 {
  margin-left: 16px;
}

.margin__left__5 {
  margin-left: 5px;
}

.margin__left__right__8 {
  margin: 0 8px;
}

.margin__0 {
  margin: 0;
}

.font__1_8__rem {
  font-size: 1.8rem;
}

.text__center {
  text-align: center;
}

.text__left {
  text-align: left;
}

.display__flex {
  display: flex;
}

.display__inline__block {
  display: inline-block;
}

.display__none {
  display: none;
}

.justify__center {
  justify-content: center;
}

.justify__space__evenly {
  justify-content: space-evenly;
}

.pointer__events__none {
  pointer-events: none;
}

.toolbar__allineatore {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
}

.margin__0 {
  margin: 0 auto;
}

.border__radius__30 {
  border-radius: 30px;
}

.border__radius__0 {
  border-radius: 0;
}

.border__0 {
  border: 0;
}

.width__auto {
  width: auto;
}

.position__absolute {
  position: absolute;
}

.position__relative {
  position: relative;
}


.style__foto__profilo {
  display: inline-block;
  border-radius: 200px;
  width: 200px;
  height: 200px;
}

.logo__style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.width__100 {
  width: 100%;
}

.width__50 {
  width: 50%;
}

.font__xsmall {
  font-size: x-small !important;
}

.welcome-page__testo {
  text-transform: uppercase;
}

.flag__selected {
  width: 25px;
  height: auto;
  /*  position: absolute;
      top: 17%;
      right: 17%; */
}

.button-outline {
  border: 2px solid;
}

.font__1_15__rem {
  font-size: 1.15rem !important;
}

.font__1_11__rem {
  font-size: 1.11rem !important;
}

.alert-button-inner {
  font-size: 1.15rem;
}

.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  font-size: 1.15rem;
}

.drop__shadow__white__text {
  filter: drop-shadow(0 0 8px rgba(72, 83, 84, 0.9));
}

.min__width__150 {
  min-width: 150px;
}

.disabled__element {
  opacity: 0.35;
  pointer-events: none;
}

.flex__direction__row {
  flex-direction: row;
}

.flex__direction__column {
  flex-direction: column;
}

.popover-ombrelloni .popover-content,
.popover-bar-ristorante .popover-content {
  width: calc(100% - 10px);
}

input {
  font-size: 16px;
  height: 32px !important;
  line-height: 24px;
  letter-spacing: normal;
  color: rgb(72, 72, 72);
  border-style: solid;
  border-radius: 4px;
  z-index: 0;
  margin-bottom: 8px;
  display: block;
  width: 100% !important;
  background-color: rgb(255, 248, 246) !important;
  border-width: 1px;
}

.margin__left__auto {
  margin-left: auto;
}

.margin__bottom__16 {
  margin-bottom: 16px;
}

.margin__right__auto {
  margin-right: auto;
}

.main__menu__icon {
  height: 46px;
  width: 46px;
}

.bottone-data {
  background: #58b8e0;
  height: 36px;
  color: white;
}

/* WAVES ANIMATION */

.height__70 {
  height: 70px;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  color: white;
}

.inner-header {
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 35px;
    min-height: 35px;
  }
}

.safe--area {
  padding-top: var(--ion-safe-area-top);
  padding-bottom: var(--ion-safe-area-bottom);
  padding-left: var(--ion-safe-area-left);
  padding-right: var(--ion-safe-area-right);
}

body {
  /*   margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top); */
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  --ion-safe-area-top: 2.1rem;
  --ion-safe-area-bottom: 2.1rem;
}

.popover-ombrelloni,
.popover-bar-ristorante,
.webCamPopOver {
  &::part(content) {
    width: calc(100% - 10px) !important;
    max-width: 600px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

ion-popover.popoverDataFine {
  --offset-y: -100px !important;
}

.popover-close-button {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
}

.popover-close-button.button-outline {
  --border-color: transparent;
  margin-bottom: 8px;
}

ion-popover {
  &::part(content) {
    width: calc(100% - 10px) !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .menu__items__container__item p {
    text-align: center;
  }

  .status-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 150px);
  }





  .ion-valid {
    --highlight-color: var(--ion-color-primary);
  }
}